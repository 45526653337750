import React, { useReducer } from 'react';
import Sadko from '../service/Sadko';
import config from '../config/app';

const initialState = {
    tune: 'A',
    drone: Sadko.DRONE_NONE,
    stringsCount: 9
};

export const Context = React.createContext(initialState);

export const Consumer = Context.Consumer;

export const actions = {
    SET_TUNE: 'set_tune',
    SET_STRINGS_COUNT: 'set_strings_count',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_TUNE: {
            document.cookie = config.cookie.tune + '=' + action.tune;
            document.cookie = config.cookie.drone + '=' + action.drone;

            return { ...state, tune: action.tune, drone: action.drone };
        }

        case actions.SET_STRINGS_COUNT: {
            const count = Sadko.getAvailableStringsCount().indexOf(action.count) >= 0 ? action.count : 9;

            document.cookie = config.cookie.stringsCount + '=' + count;

            return { ...state, stringsCount: count };
        }

        default:
            return state;
    }
};

export const Provider = ({ children, drone, tune, stringsCount }) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        tune,
        drone,
        stringsCount

    });
    const value = { state, dispatch };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};
