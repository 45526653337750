import { difference, path, sum } from 'ramda';

const availableStringsCount = [8, 9, 10, 11, 12];

const gamma = [
    'C',
    'C#',
    'D',
    'D#',
    'E',
    'F',
    'F#',
    'G',
    'G#',
    'A',
    'A#',
    'B',
];

const instrumentTune = [0, 2, 2, 1, 2, 2, 1, 2, 2, 1, 2, 2];

const lowOctave = 4;

const chordsShape = {
    'G7': [2, 4, 6, 8],
    'G': [2, 4, 6, 7],
    'Am': [1, 3, 5, 7],
    'C': [2, 3, 5, 7],
    'Dm': [1, 3, 4, 6],
    'D7': [1, 3, 6, 7],
    'E7': [1, 2, 4, 7],
    'F': [1, 3, 5, 6, 8]
};

const chordNotes = {
    'G7': ['G4', 'B4', 'D5', 'F5'],
    'G': ['G4', 'B4', 'D5'],
    'Am': ['A4', 'C5', 'E5'],
    'C': ['G4', 'C5', 'E5'],
    'Dm': ['A4', 'D5', 'F5'],
    'D7': ['A4', 'C5', 'D5'],
    'E7': ['B4', 'D5', 'E5'],
    'F': ['A4', 'C5', 'F5']
};

export default class Sadko {

    static DRONE_NONE = 'none';
    static DRONE_MAJOR = 'major';
    static DRONE_MINOR = 'minor';
    static DEFAULT_TUNE = 'G';

    static getAvailableStringsCount() {
        return [...availableStringsCount];
    }

    static getGamma() {
        return [...gamma];
    }

    static getChordNote(chord) {
        let result = '';

        for (let i = 0; i < chord.length; i++) {
            const symbol = chord.charAt(i);
            if (symbol.toUpperCase() === symbol && Number.isNaN(parseInt(symbol, 10))) {
                result += symbol;
            }
        }

        return result;
    }

    static transpose(note, countTunes) {
        const noteIndex = gamma.indexOf(note) + countTunes;

        if (noteIndex < 0) {
            return gamma[gamma.length + noteIndex];
        }

        return gamma[noteIndex > gamma.length - 1 ? noteIndex - gamma.length : noteIndex];
    }

    static transposeChordOrFullNote(note, countTunes) {
        if (!note) {
            return note;
        }

        const chordNote = this.getChordNote(note);
        const chordType = note.replace(chordNote, '');

        return `${this.transpose(chordNote, countTunes)}${chordType}`;
    }

    static transposeNoteByTune(noteInG, tune = 'G') {
        return Sadko.transposeChordOrFullNote(noteInG, Sadko.getGOffset(tune));
    }

    static getGOffset(tune) {
        const tuneIndex = gamma.indexOf(tune);
        const gIndex = gamma.indexOf('G');

        if (tuneIndex === gIndex) {
            return 0;
        }

        return tuneIndex - gIndex;
    }

    static isDroneSelected(drone) {
        return [Sadko.DRONE_MAJOR, Sadko.DRONE_MINOR].indexOf(drone) >= 0;
    }

    static getNoteString(note, tune, stringsCount = 9) {
        const matchData = /([0-9])/.exec(note);
        const octave = Array.isArray(matchData) && matchData.length > 1 ? parseInt(matchData[0]) : null;
        if (!octave) {
            throw new Error('Octave not found');
        }

        let currentOctave = lowOctave;
        const firstGammaNote = gamma[0];
        for (var stringNumber = 1; stringNumber <= stringsCount; stringNumber++) {
            const currentNote = Sadko.getStringNote(stringNumber, tune);
            if (stringNumber > 1 && firstGammaNote === currentNote) {
                currentOctave += 1;
            }

            if (currentNote + currentOctave === note) {
                return stringNumber;
            }
        }

        throw new Error(`String for note '${note}' not found`);
    }

    static getStringNote(stringNumber, tune = 'A', drone = Sadko.DRONE_NONE) {
        if (Sadko.isDroneSelected(drone)) {
            if (stringNumber === 1) {
                let unisonString = drone === Sadko.DRONE_MAJOR ? 5 : 6;
                return Sadko.getStringNote(unisonString, tune);
            }
        }

        const tuneIndex = gamma.indexOf(tune);

        let offset = sum(instrumentTune.slice(0, Sadko.isDroneSelected(drone) ? stringNumber - 1 : stringNumber));
        if (offset === 0) {
            return tune;
        }

        const gammaSize = gamma.length;

        offset = offset > gammaSize ? offset % gammaSize : offset;

        let resultIndex = tuneIndex + offset;

        resultIndex = resultIndex > gammaSize - 1 ? resultIndex - gammaSize : resultIndex;

        return gamma[resultIndex];
    }

    static getStringNoteWithOctave(stringNumber, tune = 'A', drone = Sadko.DRONE_NONE) {
        const note = Sadko.getStringNote(stringNumber, tune, drone);

        return note + (stringNumber < Sadko.getOctaveThreshold(tune) ? lowOctave : lowOctave + 1)
    }

    static getOctaveThreshold(tune = 'A', stringsCount = 9) {
        const firstGammaNote = gamma[0];
        for (var i = 1; i <= stringsCount; i++) {
            if (Sadko.getStringNote(i, tune) === firstGammaNote) {
                return i;
            }
        }
    }

    static hasChord(name) {
        return Object.keys(chordsShape).indexOf(name) >= 0;
    }

    static getChordShape(chordName, countStrings = 9, drone = undefined) {
        const shape = new Array(countStrings).fill(0, 0);
        if (!chordName) {
            return shape;
        }

        if (!(chordName in chordsShape)) {
            throw new Error(`Chord ${chordName} not found`);
        }

        chordsShape[chordName].forEach((stringNumber) => {
            shape[Sadko.isDroneSelected(drone) ? stringNumber : stringNumber - 1] = 1;
        });

        return shape;
    }

    static getMutesChordNotes(chordName, countStrings = 9, tune) {
        const shape = Sadko.getChordShape(chordName, countStrings);

        const result = [];

        shape.forEach((muted, index) => {
            if (muted) {
                result.push(Sadko.getStringNoteWithOctave(index + 1, tune));
            }
        });

        return result;
    }

    static guessChordName(notes) {
        const chordNames = Object.keys(chordNotes);
        for (var i = 0; i < chordNames.length; i++) {
            const chordName = chordNames[i];

            if (difference(chordNotes[chordName], notes).length === 0 && difference(notes, chordNotes[chordName]).length === 0) {
                return chordName;
            }
        }
    }

    static getChordNotes(chordName, tune) {
        const offset = this.getGOffset(tune);

        const gTuneChord = this.transposeChordOrFullNote(chordName, -offset);

        return path([gTuneChord], chordNotes, []).map((note) => this.transposeChordOrFullNote(note, offset));
    }
}
