import React from 'react';

const initialValue = [];

export const Context = React.createContext(initialValue);

export const Consumer = Context.Consumer;

export const actions = {
    RESET: 'reset',
    PUSH: 'push'
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.RESET:
            return [...initialValue];
        case actions.PUSH:
            return state.indexOf(action.name) < 0 ? [...state, action.name] : state;
        default:
            return state;
    }
};

export const Provider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, []);
    const value = { state, dispatch };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};
